/*SIDEBAR STYLES*/

app-sidebar-cmp {
  width: 100px;
}

//overa-logo {

/*LOGO STYLES*/

.tab-svg-object {
  width: 100%;
  pointer-events: none;
  display: block;
}

// .sidebarLogo {
//   width: -webkit-fill-available;
// }

.logo {
  width: 100%;
  /*max-width: 80px;*/
  max-width: 90px;
}

.sidebar .logo {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: $logo-corporate-color;
  height: 63px;
  text-align: center;
  transition: all 0.6s ease;
}

.sidebar .logo a {
  transition: all 0.6s ease;
}

.sidebar .logo a:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
  background-color: $logo-corporate-color;
}

.sidebar .logo a.logo-mini {
  opacity: 1;
  float: left;
  width: 30px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

/* END LOGO STYLES*/

//}

.sidebar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10001;
  box-shadow: none !important;
  border: none !important;
}

.sidebar .sidebar-wrapper {
  position: relative;
  /* height: calc(100vh - 75px);*/
  overflow: auto;
  /*width: 260px;*/
  width: 90px;
  // z-index: 4;
  padding-bottom: 30px;
}

.sidebar:hover,
.sidebar-wrapper {
  /*width: 80px !important;*/
  width: 90px !important;
  height: calc(100vh);
  background-color: $sidebar-corporate-color;
  border: 1px solid $sidebar-corporate-border-color;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 15px 0px; */
}

.sidebar:hover {
  box-shadow: none;
}

.sidebar li.ng-star-inserted>a.nav-link {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-left: 1px solid transparent;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.sidebar li.ng-star-inserted.active>a.nav-link {
  background: transparent !important;
  box-shadow: none !important;
  border-left: 6px solid $secondary-corporate-color;
  border-radius: 0px !important;
  transition: all 0.3s ease-out;
}

.sidebar li.ng-star-inserted>a.nav-link:hover {
  background: transparent !important;
  border-left: 6px solid $sidebar-corporate-color-link-selected;
  border-radius: 0px !important;
  transition: all 0.3s ease-out;
}

.sidebar-mini .sidebar,
.sidebar-mini:hover .sidebar:hover {
  width: 90px;
  text-align: center;
}

.sidebar .nav li a {
  /*margin: 15px auto;*/
  margin: 10px auto;
  padding-left: 8px;

  img {
    /*
    margin-left: 10px;
    min-width: 50px;
    max-width: 50px;
    */
    margin-left: 5px;
    min-width: 60px;
    max-width: 60px;
  }
}

.sidebar .nav-link {
  .newDesignIcon {
    font-size: 60px;
  }
}

/*END SIDEBAR STYLES*/

/*SIDEBAR RESPONSIVE STYLES*/

@media (max-width: 991px) {

  /*HEIGHT SIDEBAR*/

  .sidebar-mini .sidebar,
  .sidebar-mini:hover .sidebar:hover {
    height: 60px;
  }


  .sidebar:hover {
    box-shadow: none;
    background: transparent;
    /* visibility: hidden;*/
  }

  .sidebar-wrapper {
    display: none !important;
  }

  .sidebar .logo {
    background: none !important;
    // margin-top: 5px !important;
    margin-left: 10px;
    margin-bottom: 5px !important;
  }
}

/*END SIDEBAR RESPONSIVE STYLES*/