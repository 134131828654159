/*OVERA-LABEL*/

/*input error label*/
.o-error-label {
    font-size: $label-error-corporate-font-size;
    color: $label-error-corporate-color;
    margin-bottom: 10px;
}

/*end input error label*/


/*END OVERA LABEL*/