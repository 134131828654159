.o-card {
    .custom-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $title-card-background-color;
      color: $title-card-color !important;
      font-size: $title-card-font-size !important;
      padding: 5px 5px;
      box-shadow: $title-card-box-shadow;
    }
  
    .card {
      min-width: 350px;
      max-width: 350px;
      justify-self: center;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
      margin-top: 1%;
      margin-bottom: 1%;
  
      &.custom-card {
        border-color: #ccc;
        border-bottom-width: 4px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
        padding-bottom: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
  
      &.custom-card:hover {
        transform: scale(1.01);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
      }
    }
  
    .custom-title {
      white-space: break-word;
      display: flex;
      font-size: $title-card-font-size !important;
      cursor: pointer;
      font-weight: 600;
      margin: 0px;
      margin-left: 5px;
      margin-right: 5px;
    }
  
    .mid-header,
    .custom-percentage {
      display: flex;
      align-items: center;
    }
  
    .custom-percentage {
      margin: 0;
      // min-width: 50px;
      display: flex;
    }
  
    .custom-body {
      // padding: 5px 10px 5px 10px;
      width: 100%;
      // max-height: 200px;
      min-height: 100px;
      overflow-y: auto;
      scroll-behavior: smooth;
      scroll-snap-type: x proximity;
      cursor: pointer;
    }
  
    .custom-info {
      margin: 0;
      font-size: smaller;
    }
  
    .key {
      // text-transform: uppercase;
      color: #344767 !important;
      margin-right: 7px;
    }
  
    .custom-title.key {
      //font-weight: bold;
      color: black;
      // position: absolute;
      // top: 0px;
    }
  
    .value-title {
      // font-weight: bold;
      // margin-left: 5%;
    }
  
    .value-info {
      //font-weight: bold;
      // margin-left: 3%;
      color: #344767 !important;
    }
  
    .card-title {
      display: inline-flex;
      min-width: 150px;
      cursor: pointer;
      text-transform: uppercase;
      margin-bottom: 0px !important;
    }
  
    .card-info {
      display: flex;
      // margin-top: 4%;
      padding-right: 5px;
  
      .key {
        grid-column: 1;
      }
  
      .value-info {
        grid-column: 2;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
  
    .optional-actions {
      margin: auto;
      // font-weight: bold;
      font-size: large;
      color: black;
    }
  
    // .card .card-header {
    //    z-index: 0 !important;
    // }
  
    .black-icon {
      color: black;
    }
  
    // .dropdown {
    //   position: relative;
    //   display: inline-block;
    // }
  
    // .dropdown-content {
    //   position: absolute;
    //   border-radius: 5px;
    //   right: 0;
    //   border: 1px;
    //   background-color: #f9f9f9;
    //   min-width: 160px;
    //   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    //   padding: 12px 16px;
    //   z-index: 1;
    // }
  
    .menu-title {
      color: black;
    }
  
    .title-container:not(:last-child) {
      border-bottom: 1px solid white;
    }
  
    .right-side {
      display: flex;
    }
  
    .background-icons {
      // background-color: white;
      // padding: 0px 10px;
      background-color: #e9e2e8;
      border-radius: 5px;
      margin-left: 5px;
      margin-right: 5px;
      min-width: 15px;
    }
  
    .iconClass {
      padding: 0px 4px;
    }
    .o-card-buttons {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 10px;
      // margin-right: 1rem;
      min-height: 30px;
    }
    /*responsive card*/
  
    @media (max-width: 991px) {
      .card {
        max-width: 100% !important;
        margin-bottom: 2%;
      }
  
      .custom-body {
        overflow: inherit !important;
      }
    }
  
    /*responsive card*/
  
    @media (max-width: 991px) {
      .card {
        max-width: 100% !important;
        margin-bottom: 2%;
      }
  
      .custom-body {
        overflow: inherit !important;
      }
    }
  
    /*responsive card*/
  
    @media (max-width: 991px) {
      .card {
        max-width: 100% !important;
        margin-bottom: 2%;
      }
  
      .custom-body {
        overflow: inherit !important;
      }
    }
  }
  //menu contextual cards ...
  .mat-mdc-menu-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1 px solid grey;
  
    button {
      background-color: white;
      align-items: left;
      border: none;
      padding: 5px;
      text-align: left;
      font-size: small;
      color: $primary-corporate-color;
      font-family: $label-corporate-font-family;
    }
  }
  