overa-role-list, overa-tenant-list {
  font-family: $primary-corporate-font;
  font-size: $label-corporate-font-size;
  .card-header {
    height: 50px;
  }

  .searchInput {
    width: 100%;
  }


  #keypad {
    top: 5px !important;
    }


  

}


overa-role-form
{
  
  .card-header {
    height: 50px;
  }

  .card-header .card-title,  .card-header .card-icon
  {
  display: inline !important;
  }
 
  .card-header .mdc-icon-button
  {
    padding-top: 0px;
  }



  [type="button"]:not(:disabled)
  {
    transition: all 0.6s ease;
    margin-right: 5px;
  }

  [type="button"]:not(:disabled):hover
   {
    color:$primary-color-light;
    }


    /*center header table*/

    table thead
    {
      text-align: center;
    }

}