overa-progress-spinner {
  .circular-progress {
    display: block !important;
    margin: auto !important;
  }

  .circle-bg {
    stroke: #eee !important;
  }

  .circle {
    stroke: #4caf50 !important;
    stroke-linecap: round !important;
    animation: progress 1s ease-out forwards !important;
  }

  .percentage {
    font-weight: bold !important;
  }
}
