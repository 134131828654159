/* DMS SANS primary and secondary fonts
font-weight types: extra light (200), light(300), regular(normal), medium(500), semibold(600), extrabold(bold) */

/*!important is necessary*/

html,
.p-component {
  font-family: $primary-corporate-font, "Segoe UI" !important;
}

body {
  color: $text-color;
  font-size: $primary-corporate-font-size;
  font-family: $primary-corporate-font, "Segoe UI" !important;
  /*font: normal 125% / 1.4 $primary-corporate-font, "Segoe UI" !important;*/
 
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6  .h6,
p, span,div
{
  font-family: $secondary-corporate-font, "Segoe UI";
}

