// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.main-navbar {
  height: 63px;
}

.navbar .collapse .navbar-nav .nav-item .nav-link:not(.btn) .material-icons {
  margin-top: -3px;
  top: 0px;
  position: relative;
  color: #7eba26;
  margin-right: 3px;
}
