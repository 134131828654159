overa-profile {
  .main-content {
    margin-top: 2% !important;
  }

  .btn {
    color: $primary-color-light;
    background-color: $primary-corporate-color;
    padding: 5px;
    margin: 3px;
  }

  .btn:hover {
    background-color: $secondary-corporate-color;
  }
  .fileinput-new {
    display: none;
    .pull-right {
      display: none;
    }
  }

  .card-icon {
    display: none;
    // background: $primary-corporate-color !important;
    // background-color: $primary-corporate-color !important;
    // box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    //   0 7px 10px -5px $primary-corporate-color !important;
  }

  // .togglebutton label input[type="checkbox"]:checked + .toggle {
  //   background-color: $secondary-corporate-color;
  // }

  // .togglebutton label input[type="checkbox"]:checked + .toggle:after {
  //   border-color: $primary-corporate-color !important;
  // }


  .btn.pull-right
  {
    padding: 5px !important;
    transition: all 0.6s ease;
  }
  
  .btn.pull-right:hover
  {
    color: $primary-color-light;
    background-color: $secondary-corporate-color;
  }
  

  .form-group
  {
    padding-bottom: 10px;
  }


  input
  {
    border: 1px solid #f0f0f0 !important;
    background: #ffffff;
    color: #344767;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 6px;
  }
  


}
