//overa-multiselect {
         /*MULTISELECT STYLES*/


         .p-multiselect
         {
           background: $primary-color-light;
           border: 1px solid $select-corporate-border-color;
           color: $select-corporate-text-color;
           box-shadow: $select-corporate-box-shadow;
           border-radius: $select-corporate-border-radius;
         }


          .p-multiselect:not(.p-disabled):hover, .p-multiselect:hover
          {
            box-shadow: $select-corporate-box-shadow;
          }

          .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight
          {
            color: $select-corporate-text-color;
            background: $primary-color-light;
          }


          .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight:focus
          {
            box-shadow: none;
          }

          .p-multiselect-panel .p-multiselect-header
          {
            background: $primary-color-light;
            color: $select-corporate-text-color;
          }


         /*END MULTISELECT STYLES*/
// }
