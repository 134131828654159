// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

/*N*/

/*SPINNER (VARIABLES IN _variables.scss)*/
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $loader-container-background-color;
}

.simple-spinner {
  width: 30px;
  height: 30px;
  border: 2px solid $simple-spinner-border-color;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 0.8s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*END SPINNER (VARIABLES IN _variables.scss)*/




body {
  background-color: $background-corporate-color !important;

  /*FIRST CONTAINER*/
 
  .container-master {
    width: 100%;
    min-height: 100vh;
    height: 100%; // Asegura que el contenedor tenga el alto de la ventana del navegador
    display: flex; // Habilita Flexbox para los hijos directos de este contenedor
    flex-direction: column; // Organiza los hijos directos de este contenedor en una columna
  }

  /* END FIRST CONTAINER */


  /* SECOND CONTAINER*/
 
  .container-level-2 {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: $primary-color-light;
    padding: 10px;
    flex-grow: 1; // Ocupa cualquier espacio vertical disponible
    display: flex; // Habilita Flexbox para los hijos directos de este contenedor
    border-radius: 6px;

    /*menos espaciado arriba y laterales*/
    margin-left: 90px;
    margin-top: 0px;

    overflow-x: hidden;

    /*fin menos espaciado arriba y laterales*/
  }

  .container-saveAndReloadLayoutSection {
    /*border:1px solid $secondary-corporate-color;*/
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: $primary-color-light;
    margin-left: 105px;
    margin-top: 16px;
    margin-bottom: 10px;
    margin-right: 15px;
    padding: 10px;
    border-radius: 6px;
  }

  .container-globalFilters {
    margin-left: 105px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: $primary-color-light;
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 6px;

    /*menos espaciado arrib ay laterales*/
    margin-left: 80px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    //overflow: hidden;
    /*fin menos espaciado arrib ay laterales*/
  }

  .container-globalFilters .card {
    border: none;
  }

  /*BUTTONS FOR CONTAINER SAVE AND RELOAD SECTION*/

  #saveLayoutButton,
  #reloadSavedLayoutButton {
    padding: 5px 20px !important;
    font-size: 15px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    width: auto !important;
    transition: background-color 0.3s ease;
    border-radius: 8px !important;
    /* margin: 10px auto 0 auto;*/
    margin-right: 20px;
    color: $primary-color-light;
    background: $primary-corporate-color !important;
  }

  #saveLayoutButton:hover,
  #reloadSavedLayoutButton:hover {
    color: $primary-color-light;
    background: $secondary-corporate-color !important;
  }

  .root-layout {
    flex-grow: 1; // Ocupa cualquier espacio vertical disponible
  }

  /*END SECOND CONTAINER*/


  /*RESP0ONSIVE MOBILE STYLES*/

  @media (max-width: 991px) {
    h3 {
      margin-bottom: 0px;
      margin-left: 10px;
    }

    .container {
      max-width: 550px !important;
      padding: 0px !important;
    }

    .container-fluid {
      padding: 5px;
    }

    .container-level-2,
    .container-saveAndReloadLayoutSection,
    .container-globalFilters {
      margin-top: 10px;
      margin-left: 10px;
      /*because sidebar width*/
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px;
    }

    /*GRID COLUMN 99%*/
    .grid > .col,
    .grid > [class*="col"] {
      width: 99%;
    }
  }
}

// .p-button {
//   color: $primary-color-light;
//   background: $primary-corporate-color !important;
//   border: none;
// }

// #navigation {
//   .p-button {
//     background-color: transparent !important;
//   }

//   .p-button:enabled:hover {
//     color: inherit !important;
//   }

// }

// .p-button:hover {
//   color: $primary-color-light;
//   background: $secondary-corporate-color !important;
//   border: none;
// }

// .p-button:active,
// .p-button:focus,
// .p-button:enabled:hover {
//   box-shadow: none !important;
//   background: $secondary-corporate-color !important;
// }

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: $secondary-corporate-color !important;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  color: $primary-corporate-color !important;
}

.p-carousel .p-carousel-content .p-carousel-prev:hover,
.p-carousel .p-carousel-content .p-carousel-next:hover {
  color: $primary-color-light !important;
}

.p-link:focus {
  box-shadow: none !important;
}

/*FAB STYLES ACTIONS BUTTON BOTTOM FLOAT POSITION always visible*/

.fab-wrapper {
  position: fixed;
  bottom: 13rem;
  right: 3rem;
}

.fab-checkbox {
  display: none;
}

.fab {
  position: absolute;
  bottom: -1rem;
  right: -1rem;
  width: 4rem;
  height: 4rem;
  background: $primary-corporate-color;
  border-radius: 50%;
  background: $primary-corporate-color;
  /*box-shadow: 0px 5px 20px #81a4f1;*/
  transition: all 0.3s ease;
  z-index: 1;
  border-bottom-right-radius: 6px;
  border: 1px solid $primary-corporate-color;
  cursor: pointer;
}

.fab:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.fab-checkbox:checked ~ .fab:before {
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-color: rgba(255, 255, 255, 0.2);
}

.fab:hover {
  background: $secondary-corporate-color;
  border: 1px solid $secondary-corporate-color;
  /*box-shadow: 0px 5px 20px 5px #81a4f1;*/
}

.fab-dots {
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  transform: translateX(0%) translateY(-50%) rotate(0deg);
  opacity: 1;
  animation: blink 3s ease infinite;
  transition: all 0.3s ease;
}

.fab-dots-1 {
  left: 15px;
  animation-delay: 0s;
}

.fab-dots-2 {
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation-delay: 0.4s;
}

.fab-dots-3 {
  right: 15px;
  animation-delay: 0.8s;
}

.fab-checkbox:checked ~ .fab .fab-dots {
  height: 6px;
}

.fab .fab-dots-2 {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.fab-checkbox:checked ~ .fab .fab-dots-1 {
  width: 32px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.fab-checkbox:checked ~ .fab .fab-dots-3 {
  width: 32px;
  border-radius: 10px;
  right: 50%;
  transform: translateX(50%) translateY(-50%) rotate(-45deg);
}

@keyframes blink {
  50% {
    opacity: 0.25;
  }
}

.fab-checkbox:checked ~ .fab .fab-dots {
  animation: none;
}

.fab-wheel {
  position: absolute;
  bottom: 0;
  right: 0;
  /*border: 1px solid;*/
  width: 10rem;
  height: 10rem;
  transition: all 0.3s ease;
  transform-origin: bottom right;
  transform: scale(0);
}

.fab-checkbox:checked ~ .fab-wheel {
  transform: scale(1);
}

.fab-action {
  position: absolute;
  background: #0f1941;
  background: none !important;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: White;
  box-shadow: 0 0.1rem 1rem rgba(24, 66, 154, 0.82);
  box-shadow: none !important;
  transition: all 1s ease;

  opacity: 0;
}

.fab-checkbox:checked ~ .fab-wheel .fab-action {
  opacity: 1;
}

.fab-action:hover {
  background-color: #f16100;
  background-color: none !important;
}

.fab-wheel .fab-action-1 {
  right: 7rem;
  bottom: 0rem;
}

.fab-wheel .fab-action-2 {
  right: 14rem;
  bottom: 0rem;
}

.fab-wheel .fab-action-3 {
  right: 20rem;
  bottom: 0rem;
}

.fab-wheel .fab-action-4 {
  right: 28rem;
  bottom: 0rem;
}

/*OVERRIDE FAB*/

.fab-checkbox:checked ~ .fab-wheel {
  transform: scale(1);
  height: 50px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  border: 1px solid #81bb26 !important;
  padding-bottom: 20px !important;
  width: 533px !important;
  border-radius: 6px;
}

.fab-wheel {
  bottom: -10px !important;
  height: 50px !important;
  background: #f5f5f6 !important;
}

.fab-wheel {
  overa-button .p-button {
    margin-top: 0px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

/*END OVERRIDE FAB*/

/*END FAB STYLES ACTIONS BUTTON BOTTOM FLOAT POSITION always visible*/

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

overa-button span.pi {
  transition: all 0.6s ease;
}

overa-button button:hover span.pi-angle-right {
  transform: translateX(5px);
}

overa-button button:hover span.pi-angle-left {
  transform: translateX(-5px);
}

.link-container span.sidebar-normal {
  padding-left: 5px;
}
