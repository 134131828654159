.o-chips {
  margin: 5px;
  padding-bottom: 15px;
}

.o-chips-label {
  font-weight: 500 !important;
}

.o-chips-input {
  display: block;
  background: $primary-color-light;
  border: 1px solid $select-corporate-border-color;
  color: $select-corporate-text-color;
  box-shadow: $select-corporate-box-shadow;
  border-radius: $select-corporate-border-radius;
  width: 100%;
}

.o-chips-input ul {
  margin: 0;
  list-style-type: none;
  cursor: text;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid $autocomplete-corporate-border-color;
}

.o-chips-input ul:not(.p-disabled):hover {
  border: 1px solid $select-corporate-border-color;
  border-color: transparent !important;
  box-shadow: $select-corporate-box-shadow;
  width: 100%;
}

.o-chips-input ul:hover {
  border: 1px solid $select-corporate-border-color;
  box-shadow: $select-corporate-box-shadow;
}

.o-chips-input li {
  padding: 0.25rem 0.5rem;
  background: $primary-color-light;
  color: $autocomplete-corporate-text-color;
  border-radius: $autocomplete-corporate-border-radius;
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

// overa-chips
// {

//   /*OVERA CHIPS STYLES */

//   .p-fluid .p-chips
//     {
//     display: flex;
//     }

//     .p-chips .p-chips-multiple-container
//     {
//       padding: 0.5rem 0.5rem;
//       gap: 0.5rem;
//     }

//     .p-chips-multiple-container
//      {
//       margin: 0;
//       list-style-type: none;
//       cursor: text;
//       overflow: hidden;
//       display: flex;
//       align-items: center;
//       flex-wrap: wrap;
//       border:1px solid $chips-corporate-border-color;
//      }

//      .p-chips .p-chips-multiple-container .p-chips-token
//      {
//       padding: 0.25rem 0.5rem;
//       background: $primary-color-light;
//       color: $chips-corporate-text-color;
//       border:1px solid $chips-token-corporate-border-color;
//       border-radius: $chips-corporate-border-radius;
//     }

//     .p-chips-token
//     {
//       cursor: default;
//       display: inline-flex;
//       align-items: center;
//       flex: 0 0 auto;
//       max-width: 100%;
//     }

//     .p-chips .p-chips-multiple-container:not(.p-disabled):hover
//     {
//       border:1px solid $chips-corporate-border-color;
//      }

// }
