.o-input-number {
  /*content:styles overa inputnumber OK */
  margin: 5px !important;
  padding-bottom: 15px !important;
  width: 100% !important;

  span.p-inputnumber {
    /*content:styles overa span inputnumber OK */
    width: 100% !important;


    .p-inputnumber-input {
      /*content:styles overa p-inputnumber-input OK */
      width: 100% !important;
    }
  }

}

.o-input-number-label {
  /*content:styles overa inputnumber label OK */
  font-weight: 500 !important;
  font-size: $label-input-corporate-font-size !important;
  color: $label-input-corporate-color !important;
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  min-width: 100% !important;
  max-width: 100% !important;
  cursor: inherit !important;
}


.o-input-number-input {
  /*content:styles overa inputnumber transition width OK */
  transition: all 0.6s ease;
  width: 100%;
  display: inline-block !important;
  box-shadow: $input-corporate-box-shadow !important;
  border: 1px solid $input-corporate-border-color !important;
}




.o-input-number-input .p-inputtext {
  /*content:styles overa inputnumber p.inputtext OK */
  width: 100%;
  display: inline-block !important;
  background: $primary-color-light !important;
  border: 1px solid $input-corporate-border-color !important;
  color: $input-corporate-text-color !important;
  /* box-shadow: $input-corporate-box-shadow !important;*/
  border-radius: $input-corporate-border-radius !important;
  padding: 0.5rem 0.5rem;

}

.o-input-number-input:hover,
.o-input-number-input:enabled:hover,
.o-input-number-input:enabled:focus {
  /*content:styles overa inputnumber label hover  OK */
  border: 1px solid $input-corporate-border-color !important;
  box-shadow: $input-corporate-box-shadow-hover !important;
  transition: all 0.6s ease;
}

.o-input-number-input .p-inputtext:enabled:focus {
  /*content:styles overa inputnumber p.inputtext focus  OK */
  border: 1px solid $input-corporate-border-color !important;
  box-shadow: none !important;
}



.o-input-number-input:not(.p-disabled):hover {
  /*content:styles overa inputnumber doiabled hover OK */
  border-color: $primary-color-light !important;
}



/*fix arrows input number height*/
.p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-button.p-inputnumber-button 
{
  height: 0px;
}
