@import "src/styles/abstracts/variables";


/*OJO POR AHORA EL SUBMENU DEL NAVBAR NO ESTÁ DENTRO DEL DIV OVERA-NAVBAR*/


.mat-mdc-menu-panel 
{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.mdc-list .nav 
{
  background-color:$primary-color-light !important;
  padding-top: 10px;

    a.nav-link span 
    {
        color: #737373 !important;
        transition: 0.5s ease-in-out;
      }

      a.nav-link:hover span, li.nav-item.active a.nav-link span
      {
        color: $secondary-corporate-color !important;
      }
}


/*FIN OJO POR AHORA EL SUBMENU DEL NAVBAR NO ESTÁ DENTRO DEL DIV OVERA-NAVBAR*/




overa-navbar {
  /*NABVAR STYLES*/

  z-index: 10000;
  position: fixed;




  .navbar {
    padding: 0.625rem 0 !important;
  }

  // .navbar.navbar-transparent {
  //   height: 63px;
  //   margin-left: 0px;
  //   margin-right: 0px;
  //   background-color: $navbar-corporate-color !important;
  //   /*sticky*/
  //   position: fixed;
  //   top: 0;
  //   left: 90px;
  //   width: calc(100% - 90px);
  //   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  // }

  .navbar.navbar-transparent {

    height: 63px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 1px;
    background-color: $navbar-corporate-color !important;
    /*sticky*/
    position: fixed;
    top: 0;
    left: 90px;
    width: calc(100% - 90px);
    border-bottom: 1px solid $navbar-border-bottom-corporate-color;
    box-shadow: none !important;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  }

  .navbar-brand {
    display: none;
  }
  .navbar-wrapper{
    overflow:auto;
  }
  .navbar-wrapper .navbar-nav .nav-link {
    background-color: none;
  }

  .navbar .collapse .navbar-nav .nav-item .nav-link
  .tenant-menu .nav-link,
  .language-menu .nav-link {
    margin-left: 0px !important;
    padding: 5px !important;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    text-transform: initial !important;
    font-size: 16px !important;
    padding: 0.575rem;
  }



  .navbar li.ng-star-inserted>a.nav-link,
  .navbar li.ng-star-inserted.active>a.nav-link,
  .mat-menu-content li.ng-star-inserted>a.nav-link,
  .mat-menu-content li.ng-star-inserted.active>a.nav-link 
  {
    transition: 0.5s ease-in-out;
  }



  .navbar li.ng-star-inserted>a.nav-link:hover,
  .navbar li.ng-star-inserted.active>a.nav-link,
  .mat-menu-content li.ng-star-inserted>a.nav-link:hover,
  .mat-menu-content li.ng-star-inserted.active>a.nav-link {
    //filter: brightness(0) saturate(100%) invert(50%) sepia(68%) saturate(378%) hue-rotate(132deg) brightness(83%) contrast(88%) !important;
    color: $secondary-corporate-color;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  /*END NABVAR STYLES*/
  /*NAVBAR RESPONSIVE STYLES*/
  /*
      @media (max-width: 991px)

       {


          .navbar.navbar-transparent
          {

            box-shadow: none !important;
            background: $navbar-corporate-color;
            height: 73px;
            z-index: 100;
          }


          .div-navbarmobile
          {
            margin-top: 20px !important;
            background-color: transparent !important;
            border: none !important;
          }

          .mat-mdc-button:not(:disabled)
            {
              color: transparent;
            }



      }

      */
  /*END NAVBAR RESPONSIVE STYLES*/
  // }

  .div-navbarmobile {
    display: none;
  }

  .top-nav-responsive {
    display: none;
    z-index: 10001;
    position: relative;
    height: 0;
  }

  .menu_responsive {
    display: none;
  }

  .sidebar-mobile,
  .overa-navbar-mobile {
    display: none;
  }

  /* MEDIA QUERY FOR RESPONSIVE MENU */
  @media only screen and (max-width: 991px) {
    .overa-navbar-mobile {
      .nav-item {
        color: #fff;
      }
    }

    .sidebar {
      display: none;
    }

    .menu-nomobile {
      display: none !important;
    }

    .sidebar-mobile,
    .overa-navbar-mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .top-nav-responsive {
      display: flex;
      flex-direction: row;
      align-items: center;
      /*justify-content: space-between;*/
      justify-content: flex-end;
      background-color: transparent;
      color: #fff;
      height: 20px;
      padding: 2em;
    }

    .menu_responsive-button-container {
      display: flex;
      height: 30px;
      width: 30px;
      cursor: pointer;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .menu_responsive {
      display: flex;
      position: absolute;
      top: 65px;
      right: 0;
      margin-top: 50px;
      /*left: 0;*/
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      background-color: $sidebar-corporate-color;
    }

    .menu_responsive>li {
      overflow: hidden;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0.5em 0;
      width: 100%;
      color: #fff;
      background-color: #fff;
    }

    .menu_responsive>li:not(:last-child) {
      border-bottom: 1px solid #444;
    }

    .menu_responsive-button,
    .menu_responsive-button::before,
    .menu_responsive-button::after {
      display: block;
      background-color: #545999;
      position: absolute;
      height: 4px;
      width: 30px;
      transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
      border-radius: 2px;
    }


    .menu_responsive-button::before {
      content: "";
      margin-top: -8px;
    }

    .menu_responsive-button::after {
      content: "";
      margin-top: 8px;
    }

    #menu_responsive-toggle {
      display: none;
    }

    #menu_responsive-toggle:checked+.menu_responsive-button-container .menu_responsive-button::before {
      margin-top: 0px;
      transform: rotate(405deg);
    }

    #menu_responsive-toggle:checked+.menu_responsive-button-container .menu_responsive-button {
      background: rgba(255, 255, 255, 0);
    }

    #menu_responsive-toggle:checked+.menu_responsive-button-container .menu_responsive-button::after {
      margin-top: 0px;
      transform: rotate(-405deg);
    }

    /* #menu_responsive-toggle ~ .menu_responsive li {
            height: 0;
            margin: 0;
            padding: 0;
            border: 0;
            transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }
*/
    #menu_responsive-toggle~.menu_responsive {
      margin: 0;
      padding: 0;
      border: 0;
      display: none;
      //transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    /*#menu_responsive-toggle ~ .menu_responsive .sidebar-mobile {
            margin: 0;
            padding: 0;
            border: 0;
            display: none;
            transition: display 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }*/

    #menu_responsive-toggle:checked~.menu_responsive {
      border: 1px solid #333;
      /* height: 2.5em;*/
      padding: 0.5em;
      display: flex;
      flex-direction: column;
    }

    

    .navbar.navbar-transparent {
      width: 100%;
      left: 0;
    }


  }


}
@media only screen and (max-width: 991px) {
  overa-navbar {
    width: 100%;
    left: 0;
    top: 0;
  }

  overa-navbar {

    .p-col {
      background-color: white;
      border-radius: 4px;
      justify-content: center;

    }

    .p-button {
      background-color: white !important;
      background: white !important;
    }

    .p-button:active,
    .p-button:focus,
    .p-button:enabled:hover {
      background-color: white !important;
      color: #545999 !important;
    }

    .sidebar-mobile {
      margin-top: 20px !important;
      margin-bottom: 10px !important;
    }

  }

  .overa-navbar-mobile {
    flex-direction: column;
  }

  span.sidebar-normal {
    display: block;
  }

  .nav-link {
    text-align: center;
    margin-bottom: 10px;
  }

  overa-menu-link-tree{
    height: 80vh;
        // --QUITAR EL ALTO Y EL PADDIG TOP

    @media only screen and (max-width: 991px) { 

      .sidebar-mobile {

        height: auto !important;

        padding-top: inherit !important;

        overflow: auto; 

      }

    }
    // -- poner fondo azul en vez de verde
    
    @media only screen and (max-width: 991px) {

        background-color: $primary-corporate-color;

      }
    
    // -- la parte blanca interior con border radius

    .menu-container {

      background-color: $primary-color-light;
      height: 80vh;

      border-radius: 4px;

    }
    // -- el padre de volver en negrita

    .back-button{

      font-weight: 600;

    }

    .menu-list .li{

    cursor: pointer;

    }

    .menu-list{
      overflow: scroll;
      height: 80vh;
    }

    // volver margen con la flecha
    .back-button i.pi {

      margin-left: 20px !important;

      padding-right: 15px;

    }
    // quitar iconos del menu de la izquierda
    
    ul.menu-list > li > a > img {

      display: none !important;

    }

  }

}

@media only screen and (max-width: 991px) {
  overa-navbar #menu_responsive-toggle:checked ~ .menu_responsive {
    border: none !important;
    background-color: #545999;
  }
}