.o-dropdown {
  margin: 5px;
  padding-bottom: 15px;
}

.o-dropdown-label {
  font-weight: 500 !important;
}

.o-dropdown-input {
  display: block;
  background: $primary-color-light !important;
  border: 1px solid $select-corporate-border-color !important;
  color: $select-corporate-text-color;
  box-shadow: $select-corporate-box-shadow;
  border-radius: $select-corporate-border-radius;
  width: 100%;
}

.o-dropdown-input:hover {
  border: 1px solid $select-corporate-border-color;
  box-shadow: $select-corporate-box-shadow;
}

.o-dropdown-input:not(.p-disabled):hover {
  border-color: $primary-color-light !important;
}

// overa-dropdown {
//   /*STYLES FOR P-DROPDOWN*/

//   width: auto;

//   /*box shadow dropdown panel open*/
//   .p-select-panel {
//     box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
//       rgba(9, 30, 66, 0.13) 0px 0px 1px 0px;
//     border-top-left-radius: 0px;
//     border-top-right-radius: 0px;
//   }

//   .p-select {
//     background: $primary-color-light;
//     border: 1px solid $select-corporate-border-color;
//     color: $select-corporate-text-color;
//     box-shadow: $select-corporate-box-shadow;
//     border-radius: $select-corporate-border-radius;
//     width: 100%;
//   }

//   .p-select:hover {
//     border: 1px solid $select-corporate-border-color;
//     box-shadow: $select-corporate-box-shadow;
//   }

//   .p-select:not(.p-disabled):hover,
//   .p-inputtext:enabled:hover {
//     border-color: $primary-color-light !important;
//   }

//   /*END STYLES FOR P-DROPDOWN*/
// }
