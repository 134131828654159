// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


/*SPINNER VARIABLES*/
//$loader-container-background-color: #f5f5f5;
$loader-container-background-color:transparent;
$simple-spinner-border-color: #52D54C;
/*END SPINNER VARIABLES*/



$primary-color-light: #ffffff; // white
$primary-color-dark: #000000; // black

//$primary-corporate-color: #545999; // blue
//$secondary-corporate-color: #81bb26; // green


$primary-corporate-color: #1AB118; // GREEN 1 LIGHT
$secondary-corporate-color: #52D44C; // GREEN 2 DARK



$tertiary-corporate-color: #318C8E; // green 2

$background-corporate-color: #f7f7f8; //grey

$sidebar-corporate-color: #ffffff; // white (old grey)  _base.scss -> .sidebar-wrapper
$sidebar-corporate-border-color: #cccccc;
$navbar-corporate-color: #ffffff; 
$navbar-border-bottom-corporate-color: #cccccc;
$logo-corporate-color: #ffffff; 
$sidebar-corporate-color-link-selected: #52D44C; // GREEN 1 LIGHT


/*MENUBAR VARIABLES (TOP RIGHT: TENANT, LANGUAGE AND ICONS FOR USER AND MENU )*/

$menubar-p-menuitem-link-width:40px;
$menubar-p-menuitem-link-padding:0px;
$menubar-p-menuitem-link-margin:0px;

$menubar-p-select-label-color: #282828;
$menubar-p-select-label-padding: 0px;
$menubar-p-select-label-width: 1%;
$menubar-p-select-label-font-size: 15px;
$menubar-p-select-label-background: transparent;
$menubar-p-select-label-margin-left:15px;
$menubar-p-select-label-border: 0 none;


$menu-dropdown-hover-color:#959E95;

$menubar-p-select-dropdown-background-color:transparent;
$menubar-p-select-dropdown-color:#282828;
$menubar-p-select-dropdown-border-start-end-radius:0px;
$menubar-p-select-dropdown-border-end-end-radius:0px;
$menubar-p-select-dropdown-width: 1.7rem;




/*END MENUBAR VARIABLES (TOP RIGHT: TENANT, LANGUAGE AND ICONS FOR USER AND MENU )*/





/*Card Default*/
$title-card-background-color: #f3f3f3;
$title-card-color: #5a6ea6;
$title-card-font-size: 15px;
$title-card-box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px -1px,
  rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
/*End Card Default*/

$stepper-corporate-color: #11ada8; // blue 2
$stepper-circle-corporate-background-color: #eaeaea;
$stepper-circle-corporate-color: #ffffff;

$button_calendar_bgcolor: #2196f3 !default;

/*content: for formio button calendar OK */
$button-formio-calendar-bgcolor: #2196f3;
$button-formio-calendar-bgcolor-hover: #078586;

$background-notification-color:#F9FCFF;

/*p datatable*/
$pdatatable-corporate-head-text-color: #a8b3c4;
$pdatatable-corporate-td-text-color: #344767;
$pdatatable-corporate-td-border-bottom-color: #e9ecef;
$pdatatable-corporate-td-hover-color: #f9f9f9;
$pdatatable-corporate-button-background-color: #595295;
$pdatatable-corporate-head-font-size: 1rem;

/*on off checkbox*/
$checkbox-corporate-onoff-background-color-on: #aaa5a5;
$checkbox-corporate-onoff-background-color-off: #263866;

$checkbox-corporate-background-color: $primary-corporate-color;
$checkbox-corporate-color: $primary-color-light;
$checkbox-corporate-border-color: $primary-corporate-color;
$checkbox-corporate-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
$checkbox-corporate-box-shadow-hover: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


/*SELECT COMBO*/
$select-corporate-border-color: #f0f0f0;
$select-corporate-text-color: #344767;
$select-corporate-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
$select-corporate-border-radius: 6px;

/*CHIPS*/
$chips-corporate-border-radius: 6px;
$chips-token-corporate-border-color: #707070;
$chips-corporate-border-color: #f0f0f0;
$chips-corporate-text-color: #344767;

/*AUTOCOMPLETE*/
$autocomplete-corporate-border-radius: 6px;
$autocomplete-token-corporate-border-color: #707070;
$autocomplete-corporate-border-color: #f0f0f0;
$autocomplete-corporate-text-color: #344767;

/*INPUT*/
$input-corporate-border-color: #f0f0f0;
$input-corporate-text-color: #344767;
$input-corporate-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
$input-corporate-box-shadow-hover: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$input-corporate-border-radius: 6px;
$input-corporate-background-color-disabled: #ececec;
$input-corporate-border-color-invalid: red;
$input-corporate-box-shadow-invalid: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

/*LABELS*/
/*content: styles variables home cards OK */
$label-error-corporate-color: red;
$label-error-corporate-font-size: 13px;
$label-input-corporate-color: #364968;
$label-input-corporate-font-size: 1rem;

/*FILL CONTENT CONTAINER OVERA STEPPER*/
$fill-content-corporate-border-color: #eaeaea;
$fill-content-corporate-border-radius: 6px;

/*LABEL FORM FIELD OVERA STEPPER*/
$label-corporate-text-color: #344767;
$label-corporate-font-family: "DM Sans";
$label-corporate-font-size: 15px;

/*FORM FIELD LABEL OVERA STEPPER*/
$form-field-title-corporate-text-color: #595295;
$form-field-title-corporate-font-family: "DM Sans";
$form-field-title-corporate-font-size: 14px;
$form-field-title-corporate-background-color: #ffffff;

/// Regular font family
/// @type List
$primary-corporate-font: "DM Sans";
$primary-corporate-font-size: 15px;
$secondary-corporate-font: "DM Sans";

/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "small": 320px,
  "medium": 768px,
  "large": 1024px,
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'https://cdn.example.com/assets/';
$base-url: "/assets/" !default;
