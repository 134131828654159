.o-input-editor {
  margin: 5px;
  padding-bottom: 15px;
}

.o-input-editor-label {
  font-weight: 500 !important;
}

.o-input-editor-input {
  background: $primary-color-light;
  border: 1px solid $select-corporate-border-color;
  color: $select-corporate-text-color;
  box-shadow: $select-corporate-box-shadow;
  border-radius: $select-corporate-border-radius;
}

.o-input-editor-input:hover {
  border: 1px solid $select-corporate-border-color;
  box-shadow: $select-corporate-box-shadow;
}

.o-input-editor-input:not(.p-disabled):hover {
  border-color: $primary-color-light !important;
}
