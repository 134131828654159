.formio-component {
  margin-bottom: 1rem;
  width: 100%;
}
formio, .component-settings.formio-dialog {
  .formio-form {
    // display: flex;
    // flex-wrap: wrap;
    // width: 100%;
    // margin-top: calc(-1 * var(--bs-gutter-y));
    // margin-right: calc(-0.5 * var(--bs-gutter-x));
    // margin-left: calc(-0.5 * var(--bs-gutter-x));
    .form-control:disabled {
      background-color: $input-corporate-background-color-disabled !important;
    }

    div[ref^="nested-container"],
    div[ref^="nested-well"] {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(-1 * var(--bs-gutter-y));
      margin-right: calc(-0.5 * var(--bs-gutter-x));
      margin-left: calc(-0.5 * var(--bs-gutter-x));

      div[ref="component"]:not(.formio-component-hidden) {
        flex-shrink: 0;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
      }
    }

    // div[ref^="nested-page"],
    // div.wizard-page {
    //   display: flex;
    //   flex-wrap: wrap;
    //   width: 100%;
    //   margin-top: calc(-1 * var(--bs-gutter-y));
    //   margin-right: calc(-0.5 * var(--bs-gutter-x));
    //   margin-left: calc(-0.5 * var(--bs-gutter-x));
    //   div[ref="component"]:not(
    //       .formio-component-hidden,
    //       .formio-component-container
    //     ) {
    //     flex-shrink: 0;
    //     padding-right: calc(var(--bs-gutter-x) * 0.5);
    //     padding-left: calc(var(--bs-gutter-x) * 0.5);
    //   }
    // }

    // .builder-components {
    //   display: flex;
    //   flex-wrap: wrap;
    //   width: 100%;
    //   margin-top: calc(-1 * var(--bs-gutter-y));
    //   margin-right: calc(-0.5 * var(--bs-gutter-x));
    //   margin-left: calc(-0.5 * var(--bs-gutter-x));
    //   .builder-component {
    //     flex-shrink: 0;
    //     padding-right: calc(var(--bs-gutter-x) * 0.5);
    //     padding-left: calc(var(--bs-gutter-x) * 0.5);
    //   }
    // }

    // div.wizard-page {
    //   display: flex;
    //   flex-wrap: wrap;
    //   width: 100%;
    //   margin-top: calc(-1 * var(--bs-gutter-y));
    //   margin-right: calc(-0.5 * var(--bs-gutter-x));
    //   margin-left: calc(-0.5 * var(--bs-gutter-x));
    // }
    // div.wizard-page > div[ref="component"]:not(.formio-component-hidden) {
    //   flex-shrink: 0;
    //   padding-right: calc(var(--bs-gutter-x) * 0.5);
    //   padding-left: calc(var(--bs-gutter-x) * 0.5);
    // }
    // div.wizard-page > .formio-component:not(.formio-component-hidden) {
    //   flex-shrink: 0;
    //   padding-right: calc(var(--bs-gutter-x) * 0.5);
    //   padding-left: calc(var(--bs-gutter-x) * 0.5);
    // }
    .formio-component-hidden {
      width: 0;
    }

    .builder-components {
      div[ref^="nested-container"] {
      }
    }
    // }
    // .formbuilder {
    //   .formio-component-panel {
    //     display: flex;
    //     flex-wrap: wrap;
    //     width: 100%;
    //     margin-top: calc(-1 * var(--bs-gutter-y));
    //     margin-right: calc(-0.5 * var(--bs-gutter-x));
    //     margin-left: calc(-0.5 * var(--bs-gutter-x));
    //   }
    // }
  }

  .formio-form > div[ref="component"]:not(.formio-component-hidden) {
    // flex-shrink: 0;
    // padding-right: calc(var(--bs-gutter-x) * 0.5);
    // padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
// .formio-form
//   > .formio-builder-form
//   > div.builder-component:not(.formio-component-hidden) {
//   flex-shrink: 0;
//   padding-right: calc(var(--bs-gutter-x) * 0.5);
//   padding-left: calc(var(--bs-gutter-x) * 0.5);
// }
// .formio-builder-form {
//   display: flex;
//   flex-wrap: wrap;
//   width: 100%;
//   margin-top: calc(-1 * var(--bs-gutter-y));
//   margin-right: calc(-0.5 * var(--bs-gutter-x));
//   margin-left: calc(-0.5 * var(--bs-gutter-x));
// }
.formio-builder-form {
}
