/*LOAD CORPORATE FONTS FOR APP (NOT IDENTITY)*/

@font-face {
  font-family: 'DM Sans';
  src: url('../../assets/fonts/DMSans-ExtraLight.eot');
  src: url('../../assets/fonts/DMSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/DMSans-ExtraLight.woff2') format('woff2'),
      url('../../assets/fonts/DMSans-ExtraLight.woff') format('woff'),
      url('../../assets/fonts/DMSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../assets/fonts/DMSans-Regular.eot');
  src: url('../../assets/fonts/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/DMSans-Regular.woff2') format('woff2'),
      url('../../assets/fonts/DMSans-Regular.woff') format('woff'),
      url('../../assets/fonts/DMSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../assets/fonts/DMSans-Medium.eot');
  src: url('../../assets/fonts/DMSans-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/DMSans-Medium.woff2') format('woff2'),
      url('../../assets/fonts/DMSans-Medium.woff') format('woff'),
      url('../../assets/fonts/DMSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../assets/fonts/DMSans-SemiBold.eot');
  src: url('../../assets/fonts/DMSans-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/DMSans-SemiBold.woff2') format('woff2'),
      url('../../assets/fonts/DMSans-SemiBold.woff') format('woff'),
      url('../../assets/fonts/DMSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../assets/fonts/DMSans-ExtraBold.eot');
  src: url('../../assets/fonts/DMSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/DMSans-ExtraBold.woff2') format('woff2'),
      url('../../assets/fonts/DMSans-ExtraBold.woff') format('woff'),
      url('../../assets/fonts/DMSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../../assets/fonts/DMSans-Light.eot');
  src: url('../../assets/fonts/DMSans-Light.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/DMSans-Light.woff2') format('woff2'),
      url('../../assets/fonts/DMSans-Light.woff') format('woff'),
      url('../../assets/fonts/DMSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}



/* MATERIAL ICONS*/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}


.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

/* END MATERIAL ICONS*/

/*END LOAD CORPORATE FONTS FOR APP (NOT IDENTITY)*/
