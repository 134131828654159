.o-input {
  margin: 5px;
  padding-bottom: 15px;
}

.o-input-label {
  font-weight: 500 !important;
}

.o-input-input {
  width: 100%;
  background: $primary-color-light !important;
  border: 1px solid $input-corporate-border-color !important;
  color: $input-corporate-text-color;
  box-shadow: $input-corporate-box-shadow;
  border-radius: $input-corporate-border-radius;
  font-family: $primary-corporate-font;
}

.o-input-input:hover {
  border: 1px solid $input-corporate-border-color;
  box-shadow: $input-corporate-box-shadow;
}

.o-input-input:disabled {
  background-color: $input-corporate-background-color-disabled;
  box-shadow: none;
  opacity: 1;
}

// overa-input {
//   .p-inputtext {
//     width: 100%;
//     background: $primary-color-light;
//     border: 1px solid $input-corporate-border-color;
//     color: $input-corporate-text-color;
//     box-shadow: $input-corporate-box-shadow;
//     border-radius: $input-corporate-border-radius;
//     font-family: $primary-corporate-font;
//   }

//   .p-inputtext:hover {
//     border: 1px solid $input-corporate-border-color;
//     box-shadow: $input-corporate-box-shadow;
//   }

//   /*input disable*/

//   .p-inputtext:disabled {
//     background-color: $input-corporate-background-color-disabled;
//     box-shadow: none;
//     opacity: 1;
//   }

//   /*STYLES FOR P-DROPDOWN*/

//   /*box shadow dropdown panel open*/

//   .p-select-panel {
//     box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
//       rgba(9, 30, 66, 0.13) 0px 0px 1px 0px;
//     border-top-left-radius: 0px;
//     border-top-right-radius: 0px;
//   }

//   .p-select {
//     background: $primary-color-light;
//     border: 1px solid $select-corporate-border-color;
//     color: $select-corporate-text-color;
//     box-shadow: $select-corporate-box-shadow;
//     border-radius: $select-corporate-border-radius;
//   }

//   .p-select:hover {
//     border: 1px solid $select-corporate-border-color;
//     box-shadow: $select-corporate-box-shadow;
//   }

//   .p-select:not(.p-disabled):hover,
//   .p-inputtext:enabled:hover {
//     border-color: $primary-color-light !important;
//   }

//   /*END STYLES FOR P-DROPDOWN*/

//   /*LABEL FORM FIELD DETAILS PROJECT*/

//   .formField label {
//     color: $label-corporate-text-color !important;
//     font-family: $label-corporate-font-family !important;
//     font-size: $label-corporate-font-size !important;
//   }

//   .formField label b {
//     font-weight: 500 !important ;
//   }

//   .formField {
//     color: $label-corporate-text-color !important;
//     font-family: $label-corporate-font-family !important;
//     font-size: $label-corporate-font-size !important;
//   }

//   //}

//   /*END LSABEL FORM FIELD DETAILS PROJECT*/
// }
