.o-autocomplete {
  margin: 5px;
  padding-bottom: 15px;
}

.o-autocomplete-label {
  font-weight: 500 !important;
}

.o-autocomplete-input {
  display: block;
  background: $primary-color-light;
  border: 1px solid $select-corporate-border-color;
  color: $select-corporate-text-color;
  box-shadow: $select-corporate-box-shadow;
  border-radius: $select-corporate-border-radius;
  width: 100%;
}

.o-autocomplete-input ul {
  margin: 0;
  list-style-type: none;
  cursor: text;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid $autocomplete-corporate-border-color;
}

.o-autocomplete-input ul:not(.p-disabled):hover {
  border: 1px solid $select-corporate-border-color;
  border-color: transparent !important;
  box-shadow: $select-corporate-box-shadow;
  width: 100%;
}

.o-autocomplete-input ul:hover {
  border: 1px solid $select-corporate-border-color;
  box-shadow: $select-corporate-box-shadow;
}

.o-autocomplete-input li {
  padding: 0.25rem 0.5rem;
  background: $primary-color-light;
  color: $autocomplete-corporate-text-color;
  border-radius: $autocomplete-corporate-border-radius;
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

// overa-autocomplete
// {

//     .p-autocomplete .p-autocomplete-multiple-container {
//       padding: 0.5rem 0.5rem;
//       gap: 0.5rem;
//     }

//     .p-autocomplete-multiple-container {
//       margin: 0;
//       list-style-type: none;
//       cursor: text;
//       overflow: hidden;
//       display: flex;
//       align-items: center;
//       flex-wrap: wrap;
//       border:1px solid $autocomplete-corporate-border-color;
//   }

//       .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
//         padding: 0.25rem 0.5rem;
//         background: $primary-color-light;
//         color: $autocomplete-corporate-text-color;
//         border:1px solid $autocomplete-token-corporate-border-color;
//         border-radius: $autocomplete-corporate-border-radius;
//     }

//     .p-autocomplete-token {
//       cursor: default;
//       display: inline-flex;
//       align-items: center;
//       flex: 0 0 auto;
//   }

//         .p-autocomplete-token-icon {
//           line-height: 2px !important;
//           margin-left: 0.2rem !important;
//         }

//         .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover
//          {
//           border: 1px solid $select-corporate-border-color;
//           box-shadow: $select-corporate-box-shadow;
//         }

//         .p-autocomplete .p-autocomplete-multiple-container:hover
//          {
//           border: 1px solid $select-corporate-border-color;
//           box-shadow: $select-corporate-box-shadow;
//         }

// }
