overa-user-list {
  font-family: $primary-corporate-font;
  font-size: $label-corporate-font-size;
  .card-header {
    height: 50px;
  }

  .searchInput {
    width: 100%;
  }

  #keypad {
    top: 5px !important;
    }
}


overa-user-form
{
  

  [type="button"]:not(:disabled)
  {
    transition: all 0.6s ease;
  }

  [type="button"]:not(:disabled):hover
   {
    color:$primary-color-light;
    }

}