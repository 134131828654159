.o-checkbox {
  margin: 5px;
  padding-bottom: 15px;
}

.o-checkbox-label {
  font-weight: 500 !important;
}

overa-datatable {
  .o-checkbox {
    margin: 1px;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
  }

  .o-checkbox-checklabel::after {
    top: 1.5px !important;
    left: 10px !important;
  }
}

// overa-checkbox {
//   p.onoff {
//     input {
//       display: none;
//     }

//     label {
//       background-color: $checkbox-corporate-onoff-background-color-on !important;
//       box-shadow: none !important;
//     }

//     input:checked + label {
//       background: $checkbox-corporate-onoff-background-color-off !important;
//       box-shadow: none !important;
//     }

//     label::after {
//       left: 10px !important;
//       top: 0px !important;
//     }
//   }

//   /*SELECT COMBO CHECKBOX*/

//   .p-checkbox .p-checkbox-box.p-highlight {
//     background-color: $secondary-corporate-color;
//     border-color: $secondary-corporate-color;
//   }

//   .p-checkbox .p-checkbox-box.p-highlight:hover {
//     background-color: $primary-corporate-color;
//     border-color: $primary-corporate-color;
//   }

//   .p-checkbox:not(.p-checkbox-disabled):hover {
//     box-shadow: none;
//   }

//   .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
//     top: 9px;
//     left: 3px;
//   }

//   /*END SELECT COMBO CHECKBOX*/
// }
