overa-datatable {
  overa-button {
    .p-button {
      border-radius: 50%;
      transition: all 0.6s ease;
      width: 35px !important;
      height: 35px !important;
      padding: 0px !important;
    }
  }

  .p-datatable .p-datatable-header,
  .p-datatable-table > .p-datatable-thead {
    background: $primary-color-light;
    border: none;
    text-transform: uppercase;
  }

  .p-datatable .p-datatable-thead > tr > th {
    border-bottom: 1px solid $primary-corporate-color;
    background: $primary-color-light;
    color: $pdatatable-corporate-head-text-color;

    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid $primary-corporate-color;
    border-width: 0 0 1px 0;
    font-weight: 600;
    transition: none;
  }

  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $pdatatable-corporate-head-text-color;
  }

  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background-color: $primary-color-light !important;
  }

  .p-datatable .p-sortable-column.p-highlight,
  .p-datatable .p-sortable-column.p-highlight:hover,
  .p-datatable .p-sortable-column:hover {
    box-shadow: none !important;
  }

  .p-datatable .p-sortable-column.p-highlight:hover {
    background: $primary-color-light !important;
    color: $primary-corporate-color;
  }

  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight) {
    transition: all 0.6s ease;
  }

  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):hover {
    background: $pdatatable-corporate-td-hover-color;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 8px !important;
    color: $pdatatable-corporate-td-text-color;
    border-color: $pdatatable-corporate-td-border-bottom-color;
    text-align: left;

    border: 1px solid $pdatatable-corporate-td-border-bottom-color;
    border-width: 0 0 1px 0;
  }

  .p-datatable .p-datatable-tbody > tr {
    background: $primary-color-light;
    color: $pdatatable-corporate-td-text-color;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    transition: all 0.6s ease;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    font-weight: bold;
  }

  /*PAGINATOR*/
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background: $primary-color-light;
    border: 1px solid $input-corporate-border-color;
    color: $input-corporate-text-color;
    box-shadow: $input-corporate-box-shadow;
    border-radius: $input-corporate-border-radius;
    transition: all 0.6s ease;
  }

  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover,
  .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: $secondary-corporate-color;
    color: $primary-color-light;
  }

  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):focus,
  .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):focus,
  .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):focus,
  .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):focus,
  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):focus,
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus {
    box-shadow: none !important;
  }
  .p-paginator .p-inputtext {
    box-shadow: none !important;
    transition: all 0.6s ease;
  }

  .p-paginator .p-select-panel .p-select-items .p-select-item.p-highlight {
    color: $primary-color-light;
    background: $secondary-corporate-color;
  }

  .p-paginator
    .p-select-panel
    .p-select-items
    .p-select-item:not(.p-highlight):not(.p-disabled):hover {
    color: $primary-color-light;
    background: $secondary-corporate-color;
    transition: all 0.6s ease;
  }

  .p-paginator .p-select .p-select-trigger {
    margin-top: 7px !important;
  }

  /*ARROW BOTTON WHITE*/

  .p-button.p-button-text.p-button-plain {
    color: $primary-color-light !important;
  }

  /*text content table not bold*/

  @media (max-width: 767px) {
    .default-card-header {
      font-weight: normal !important;
    }
  }
}
