/* Base Layout Styles
----------------------------------------*/
.lm_goldenlayout {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAGFBMVEX29vb19fXw8PDy8vL09PTz8/Pv7+/x8fGKuegbAAAAyUlEQVR42pXRQQ7CMBRDwST9pfe/MahEmgURbt7WmpVb6+vG0dd9REnn66xRy/qXiCgmEIIJhGACIZhACCYQgvlDCDFIEAwSBIMEwSBBMEgQDBIEgwTBIEEwCJEMQiSDENFMQmQzCZEbNyGemd6KeGZ6u4hnXe2qbdLHFjhf1XqNLXHev4wdMd9nspiEiWISJgqECQJhgkCYIBAmCIQJAmGCQJggECYJhAkCEUMEwhCBMEQgDJEIQ2RSg0iEIRJhiB/S+rrjqvXQ3paIJUgPBXxiAAAAAElFTkSuQmCC);
}

.lm_content {
  overflow: auto;
  padding: 10px;
  container-type: inline-size;
}

.lm_item {
  background-color: #ffffff !important;
}


/* Header & Tab Styles
----------------------------------------*/
.lm_tab {
  background-color: unset;
}

.lm_header {

  /* Header specific styles */
  .lm_tab {
    height: 28px !important;
    padding: 0 40px 0 25px;

    &.noLock {
      padding-right: 25px !important;
    }

    &.lm_active {
      padding-bottom: 1px;
      background-color: #f5f5f5;
    }

    .lm_title {
      padding-top: 3px;
    }

    .lm_close_tab {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAIklEQVR4nGNgYGD4z4Ad/Mdg4ODDBXCZRFgCp5EEHQMXBwAQAgz0SVCcggAAAABJRU5ErkJggg==);
      width: 20px;
      height: 20px;
      right: 0;
      top: 2px;
      opacity: 1 !important;
    }
  }

  .lm_tabdropdown_list .lm_tab {
    padding-right: 30px;
    background: white !important;
    color: black !important;
    border: 1px solid #CCCCCC;
    z-index: 10000000000000000000 !important;
  }

  .lm_controls {
    >* {
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.4;
      transition: opacity 300ms ease;
    }

    .lm_tabdropdown:before {
      vertical-align: super;
    }
  }
}

/* Selected State Styles
----------------------------------------*/
.lm_selected {
  .lm_header {
    background-color: unset !important;

    .lm_tab.lm_active {
      font-weight: bold !important;
    }
  }
}

/* Tab Icons and Controls
----------------------------------------*/
.tabBlocker {
  position: absolute;
  right: 20px;
  top: 2px;

  .tabBlockIcon {
    font-size: 12px;
  }
}

.tabActiveHidden {
  display: none;
  position: absolute;
  left: 5px;
  top: 5px;
}

.tabActive {
  position: absolute;
  left: 5px;
  top: 5px;

  .activeIcon {
    font-size: 12px;
  }
}

.lm_close_tab .tabCloseIcon {
  font-size: 12px;
}


// .root-layout {
//   position: relative;
// }

// #routerOuletContainer {
//   display: none; //inicial para que no interfiera con la home
//   width: 100%;
//   height: 100%;
// }

// #routerOuletContainer,
// app-golden-layout-host {
//   position: absolute;
//   top: 0;
//   left: 0;
// }

// .lm_goldenlayout {
//   background: none !important;
// }



// /* Aplicar negrita a todos los elementos de la tab seleccionada
// NOTA: Los estilos anteriores parecen no funcionar por no existir
// un elemento con la clase lm_selected */
// .lm_tab.lm_active.lm_focused * {
//   font-weight: bold !important;
// }



// /*CONTAINER FLUID LM GOLDEN LAYOUT*/

// .lm_goldenlayout .lm_content .container-fluid {
//   padding: 10px;
// }



// /*END CONTAINER FLUID LM GOLDEN LAYOUT*/