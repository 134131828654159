.o-datepicker {
  margin: 5px;
  padding-bottom: 15px;
}

.o-datepicker-label {
  font-weight: 500 !important;
}

.o-datepicker-input button {
  color:  $primary-color-light !important;
  background:  $secondary-corporate-color !important;
}

.o-datepicker-input {
  background: $primary-color-light;
  border: 1px solid $select-corporate-border-color;
  color: $select-corporate-text-color;
  box-shadow: $select-corporate-box-shadow;
  border-radius: $select-corporate-border-radius;
}

.o-datepicker-input:hover {
  border: 1px solid $select-corporate-border-color;
  box-shadow: $select-corporate-box-shadow;
}

.o-datepicker-input:not(.p-disabled):hover {
  border-color: $primary-color-light !important;
}



    /*ojo no está dentro de o-datepicker*/
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year
    {
      color:  $primary-color-light !important;
      background:  $tertiary-corporate-color !important;
      padding: 0.3rem;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:hover
    {
      color:  $primary-color-light !important;
      background:  $secondary-corporate-color !important;
    
    }

    .p-datepicker .p-timepicker button:enabled:hover, .p-datepicker-next:hover, .p-datepicker-prev:hover
    {
      color:  $primary-color-light !important;
    } 


    .p-datepicker-buttonbar .p-button.p-button-text 
    {
    color:  $primary-color-light !important;
    }

