overa-menu-list {
  font-family: $primary-corporate-font;
  font-size: $label-corporate-font-size;
  .card-header {
    height: 50px;
  }

  #keypad {
    right: 20px !important;
    top: 5px !important
  }

  .searchInput {
    width: 100%;
  }
  img {
    width: 50px;
  }
}
