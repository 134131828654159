/*CAROUSEL SLIDER HID OVERFLOW X*/

.o-grid
{

.drag-scroll-content
{
  overflow-x: hidden !important;
}
/*END CAROUSEL SLIDER HID OVERFLOW X*/


}