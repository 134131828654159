overa-login {
  .wrapper-full-page {
    .page-header.login-page {
      .container {
        .row {
          .col-lg-4.col-md-6.col-sm-6.ms-auto.me-auto {
            .card.card-login {
              .card-header {
                background: linear-gradient(
                  60deg,
                  $secondary-corporate-color,
                  $primary-corporate-color
                ) !important;
                text-align: center;
                h2 {
                  padding-top: 10px;
                }
              }
              .card-body {
                position: relative;
                // z-index: 1;
              }
              .card-footer {
                .btn {
                  background-color: $primary-corporate-color !important;
                  border-color: $primary-corporate-color !important;
                  color: #fff !important;
                }
                .btn:hover {
                  background-color: $secondary-corporate-color !important;
                  border-color: $secondary-corporate-color !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
