$fa-font-path: "~font-awesome/fonts";
@import "font-awesome/scss/font-awesome";
@import "bootswatch/dist/cosmo/_variables.scss";

//$web-font-path: "../assets/css/source-sans-pro-wght-300-400-700-display-swap.css" !default;

$web-font-path: "googlefonts/_fonts.css" !default;
@import "bootstrap/scss/bootstrap.scss";
@import "bootswatch/dist/cosmo/_bootswatch.scss";

overa-formeditor {
  z-index: 10005;

  .wizard-page-label {
    background-color: blue !important;
  }
}