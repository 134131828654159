
/*MENU BAR STYLES (TOP RIGHT BUTTONS, COMBO TENANT, LANGUAGE AND USER AND HAMBURGER ICONS)*/

overa-menubar 
{

      // Without background color and border
      .p-menubar
      {
        background-color: transparent;
        border: none;
      }

      // Remove tick
      .p-menubar .p-menuitem-link .p-submenu-icon 
      {
        display: none;
      }

      // Set menu item width
      .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link
      {
        width: $menubar-p-menuitem-link-width;
        padding: $menubar-p-menuitem-link-padding;
        margin: $menubar-p-menuitem-link-margin;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .p-menuitem-icon
         {
          margin: 5px;
        }

      }

      /*Dropdown styles for menubar*/

      .menu-dropdown {
        border: none ;
        box-shadow: none;
        margin-left: 5px;
        margin-right: 5px;

      }


      .p-select-label
       {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        flex: 1 1 auto;
        width: $menubar-p-select-label-width;
        padding: $menubar-p-select-label-padding;
        color: $menubar-p-select-label-color;
        font-size: $menubar-p-select-label-font-size;
        background: $menubar-p-select-label-background;
        margin-left: $menubar-p-select-label-margin-left;
        border: $menubar-p-select-label-border;
        text-overflow: ellipsis;
        cursor: pointer;
        outline: 0 none;
        transition: all 0.6s ease;
      }

     

      .p-select-dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background: $menubar-p-select-dropdown-background-color;
        color: $menubar-p-select-dropdown-color;
        border-start-end-radius: $menubar-p-select-dropdown-border-start-end-radius;
        border-end-end-radius: $menubar-p-select-dropdown-border-end-end-radius;
       /* width: var(--p-select-dropdown-width); */
        width: $menubar-p-select-dropdown-width;
        
      }

     

      .menu-dropdown:hover .p-select-label,
      .menu-dropdown:hover .p-select-dropdown 
      {
        color: $menu-dropdown-hover-color;
        transition: all 0.6s ease;
      }



      /*USER ICON*/
      .p-button, .p-button:not(:disabled)
      {
        background: transparent;
        border: none;
        color: #282828;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.6s ease;
      }

      
      .p-button:not(:disabled):hover
      {
        background: transparent;
        border: none;
        color: #959E95;
    
      }
      /*END USER ICON*/


      /*PI BARS ICON*/

      .p-button span.pi-bars
      {
        transition: all 0.6s ease;
      }

      .p-button span.pi-bars:hover
      {
        color: #959E95 !important;
      }    
      /*END PI BARS ICON*/



            /*select option styles*/
            .p-select-option
            {
              font-size: $primary-corporate-font-size;
              padding: 6px;
            }

            .p-grid > .p-col
            {
              align-items: center;
            }

             
            .p-select-option.p-select-option-selected.p-focus, .p-select-option.p-select-option-selected
             {
                background:  #959E95;
                color: #F8F7F4;
              }

              .p-select-option:not(.p-select-option-selected):not(.p-disabled).p-focus 
              {
                /*
                background: var(--p-select-option-focus-background);
                color: var(--p-select-option-focus-color);
                */
                background:  #959E95;
                color: #F8F7F4;
              }

             
            /**end select option styles */

      /*end Dropdown styles for menubar*/

}



 /* MEDIA QUERY FOR RESPONSIVE MENU-BAR */
 @media only screen and (max-width: 991px) {

  overa-menubar {
    width: 100%;

    .menubar-main-container {
      width: 100%;
    }

    .menubar-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }

    .menubar-item {
      display: flex;
      /*  width: 100%;*/
      justify-content: center;
    }
  }

    /*
    #menu_responsive-toggle:checked ~ .menu_responsive overa-menubar {
    border: 1px solid #333;
    display: flex;
    padding: 0.5em;
    transition: display 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }*/

}
 /* MEDIA QUERY FOR RESPONSIVE MENU-BAR */