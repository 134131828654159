.o-button {
  .o-button-button {
    color: $primary-color-light;
    background: $secondary-corporate-color !important;
    padding: 10px 20px !important;
    font-size: 16px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: auto !important;
    justify-content: center;
    align-items: center;

    transition: background-color 0.3s ease;
    margin: 10px auto 0 auto;
    border-radius: 20px;
  }

  .o-button-button:hover,
  .o-button-button:enabled:hover {
    color: $primary-color-light;
    background: $primary-corporate-color !important;
    padding: 10px 20px !important;
    font-size: 16px;
    transition: all 0.6s ease;
  }
}
