.o-text-area {
  margin: 5px;
  padding-bottom: 15px;
}

.o-text-area-label {
  font-weight: 500 !important;
}

.o-text-area-input {
  display: block;
  background: $primary-color-light;
  border: 1px solid $select-corporate-border-color;
  color: $select-corporate-text-color;
  box-shadow: $select-corporate-box-shadow;
  border-radius: $select-corporate-border-radius;
  width: 100%;
}

.o-text-area-input:hover {
  border: 1px solid $select-corporate-border-color;
  box-shadow: $select-corporate-box-shadow;
}

.o-text-area-input:not(.p-disabled):hover {
  border-color: $primary-color-light !important;
}
