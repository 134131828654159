@import "node_modules/primeicons/primeicons.css";

// @import "node_modules/primeng/resources/themes/mdc-light-indigo/theme.css";
// @import "node_modules/primeng/resources/primeng.min.css";
// @import "node_modules/primeng/resources/themes/saga-blue/theme.css";
// @import "node_modules/primeflex/primeflex.css";

.p-tabview .p-tabview-nav {
  border: 0px;
}

p-dynamicdialog {
  .customDynamicDialog .p-dialog-content {
    padding: 0;
    //overflow-y: visible !important;
    height: 100%;
  }
}
