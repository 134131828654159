//overa-breadcrumbs {

/* BREADCRUMBS */


.o-breadcrumbs h4 {
  margin-left: 10px !important;
  font-size: 20px !important;
}

.pl-0 {
  padding-left: 0px;
}

.breadcrumbs {
  font-family: $primary-corporate-font;
  margin-top: 5px;
  padding-left: 0px !important;
}

span.breadcrumb-left {
  font-size: 21px;
  font-weight: bold;
  color: $primary-corporate-color;
  margin-right: 5px;
}

span.breadcrumb-right {
  font-size: 20px !important;
  color: $secondary-corporate-color;
}

span.breadcrumb-left:before {
  // content: url("/assets/icons/hoja-breadcrumb.svg");
  margin-top: 30px !important;
  padding-top: 30px !important;
  top: -19px;
  left: 20px;
  position: relative;
}


.breadcrumb-separator {
  font-size: 18px !important;
}

/* END BREADCRUMBS */

/*BREACRUMBS RESPONSIVE STYLES*/

@media (max-width: 991px) {
  span.breadcrumb-left {
    display: flex;
    font-size: 16px !important;
  }

  span.breadcrumb-left:before {
    // content: url("/assets/icons/hoja-breadcrumb.svg");
    margin-top: 0px !important;
    padding-top: 0px !important;
    top: -15px;
    left: 15px;
    position: relative;
  }

  span.breadcrumb-right {
    display: flex;
    margin-left: 20px;
    font-size: 16px !important;
  }
}

/*END BREADCRUMBS RESPONSIVE STYLES*/

// }