overa-stepper {
  .step-content {
    border: 2px solid $stepper-corporate-color !important;
    padding: 10px;
    /*padding-top: 40px;*/
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    z-index: 1;
    border-radius: 6px;
    background-color: $primary-color-light !important;
    min-height: 750px;
  }

  /* space between numbers */
  .multi-steps {
    width: 40% !important;
    margin: 0px auto !important;
    cursor: pointer;
  }

  .multi-steps>li,
  .multi-steps>li:before,
  .multi-steps>li:after {
    transition: all 0.6s ease;
  }

  .multi-steps>li:before {
    line-height: 34px !important;
  }

  .multi-steps>li.is-active~li:before {
    background-color: $stepper-circle-corporate-background-color !important;
    border-color: $stepper-circle-corporate-background-color !important;
    color: $stepper-circle-corporate-color !important;
  }

  .multi-steps li.is-active:before {
    background-color: $stepper-corporate-color !important;
    border-color: $stepper-corporate-color !important;
  }

  .multi-steps>li.is-active~li:after,
  .multi-steps>li.is-active:after {
    background-color: $stepper-circle-corporate-background-color !important;
    color: $stepper-circle-corporate-color !important;
  }

  /*FILL CONTENT STYLES*/

  .fill-content {
    border: 2px solid $fill-content-corporate-border-color !important;
    border-radius: $fill-content-corporate-border-radius !important;
  }

  /*EN FILL CONTENT STYLES*/

  /*LABEL FORM FIELD DETAILS PROJECT STYLES*/

  .formField label {
    color: $label-corporate-text-color !important;
    font-family: $label-corporate-font-family !important;
    font-size: $label-corporate-font-size !important;

    width: 99%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .formField label b {
    font-weight: 500 !important;
  }

  /*form field title content*/

  .title-content span.background-default {
    background: $form-field-title-corporate-background-color !important;
    background-color: $form-field-title-corporate-background-color !important;
    color: $form-field-title-corporate-text-color !important;
    font-family: $form-field-title-corporate-font-family !important;
    font-size: $form-field-title-corporate-font-size !important;

    padding-left: 10px !important;
    padding-right: 10px !important;
    text-transform: uppercase;
  }






  /*BREACRUMBS RESPONSIVE STYLES*/

  @media (max-width: 991px) {

    .multi-steps {
      width: 90% !important;

    }

    /*left container adjust width overa stepper*/
    .fix-left {
      width: 100%;
      padding-top: 0px;
      padding-left: 0%;
      padding-right: 0px;
    }

    .formField label {
      width: 98%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .formField {
      padding-left: 10px;
    }

    .title-content {
      width: 90%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 0px;
      text-align: center;
    }

    .fill-content {
      padding-top: 40px;
      padding-left: 10px;
    }
  }

  /*END LABEL FORM FIELD DETAILS PROJECT STYLES*/





  /*End overa-stepper*/
}