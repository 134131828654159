.formio-form // STYLES ONLY FOR FORMIO 
{

  /*formio hidden*/

  .o-button-help {
    width: 20px;
  }

  .o-input-help {
    width:auto;
  }

  .formio-hidden {
    display: none;
  }

  /*datagrid table hide border*/

  .formio-component-datagrid .datagrid-table,
  .formio-component-datagrid .datagrid-table th,
  .formio-component-datagrid .datagrid-table tr,
  .formio-component-datagrid .datagrid-table td {
    border: none !important;
  }

  /*end datagrid table hide border*/

  
  /*Data Grid component*/

    /*HIDE DATAGRID SCROLL SARA NITO OJO SE QUITO PARA EVITAR BUG DE SCROLL A PARTIR DEL COMBO*/
  .formio-component-datagrid 
  {
    overflow-x: inherit; 
  }


     
    /*change icon datagrid remove button*/
    .formio-component-panel .formio-component-datagrid .formio-button-remove-row .fa-times-circle-o::before 
    {
      content: "\f1f8" !important;
    }
    /*end change icon datagrid remove button*/
 


    .formio-component-datagrid.mb-0 .card-body.mb-3
    {
        margin-bottom: 1px !important;
    }



/*End Data grid component*/

 

/*remove button red*/

.formio-button-remove-row
{
  background-color:#dc3545;
  border-color: #dc3545;
  transition: all 0.6s ease;
  border-radius: 20px;
}

.formio-button-remove-row:hover
{
  background-color:#c82333;
  border-color: #c82333;
}



  





  /*spaces margins paddings*/

  .formio-component {
    /*content:styles formio INPUTS OK */
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .formio-choices.form-group {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  /* the padding between elements*/
  .form-group {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  /*fix spaces between checkboxes*/
  .form-group.formio-component-checkbox {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .form-group.formio-component-checkbox.display-inline {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  div[ref^="nested-no-well-with-border"],
  div[ref^="nested-well-with-border"],
  div[ref^="nested-well"] {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  /*PADDING LEFT FIX*/
  .form-check {
    padding-left: 0px !important;
  }

  /*end spaces margins paddings*/

  /*FORMIO INPUTS*/
  /*content:styles formio INPUTS OK */
  .formio-component-textfield,
  .formio-component-number,
  .formio-component-password,
  .formio-component-time,
  .formio-component-currency,
  .formio-component-email,
  .formio-component-url,
  .formio-component-phoneNumber,
  .formio-component-address,
  .formio-day-component-year,
  .formio-component-day,
  .formio-component-signature {
    input {
      /* margin: 5px !important;*/
      width: 100% !important;
      background: $primary-color-light !important;
      border: 1px solid $input-corporate-border-color !important;
      border-radius: $input-corporate-border-radius !important;
      box-shadow: $input-corporate-box-shadow !important;
      color: $input-corporate-text-color !important;
      font-family: $primary-corporate-font !important;
      transition: all 0.6s ease !important;
      font-family: $primary-corporate-font !important;
      font-size: 1rem !important;
      font-weight: normal !important;

      padding: 0px !important;
      padding-left: 10px !important;
      height: 45px !important;

      /*padding: 10px 10px !important;*/
    }

    input:hover,
    input:enabled:hover,
    input:enabled:focus {
      /*content:styles formio input hover focus OK */
      border: 1px solid $input-corporate-border-color !important;
      box-shadow: $input-corporate-box-shadow-hover !important;
    }

    input:disabled,
    .formio-component-textfied:disabled,
    .formio-component-password:disabled,
    .formio-component-time:disabled,
    .formio-component-currency:disabled,
    .formio-component-email:disabled,
    .formio-component-url:disabled,
    .formio-component-phoneNumber:disabled,
    .formio-component-address:disabled,
    .formio-day-component-year:disabled,
    .formio-component-day:disabled,
    .formio-component-signature:disabled,
    .formio-component-datetime input:disabled {
      /*content:styles formio input disbled OK */
      background-color: $input-corporate-background-color-disabled !important;
      box-shadow: none !important;
      opacity: 1 !important;
      font-weight: normal !important;

      padding: 0px !important;
      padding-left: 10px !important;
      height: 45px !important;
    }

    /*campo invalido*/
    input.ng-dirty.ng-invalid {
      /*content:styles formio input hover invalid OK */
      border: 1px solid $input-corporate-border-color-invalid !important;
      box-shadow: $input-corporate-box-shadow-invalid !important;
    }

    input:enabled:focus.ng-invalid.ng-dirty {
      /*content:styles formio input hover invalid OK */
      border: 1px solid $input-corporate-border-color-invalid !important;
      box-shadow: $input-corporate-box-shadow-invalid !important;
    }

    input.ng-dirty.ng-invalid {
      /*content:styles formio input hover invalid OK */
      border: 1px solid $input-corporate-border-color-invalid !important;
      box-shadow: $input-corporate-box-shadow-invalid !important;
    }
  }

  /*END FORMIO INPUT*/

  /*FORMIO LABEL*/

  label:not(.o-checkbox-checklabel):not(.form-check-label) {
    /*content:styles formio LABELS OK */
    font-family: $primary-corporate-font !important;
    font-weight: 500 !important;
    font-size: $label-input-corporate-font-size !important;
    color: $label-input-corporate-color !important;
    display: inline-block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-width: 100% !important;
    max-width: 100% !important;
    cursor: inherit;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-left: 5px;
  }

  /*END FORMIO LABEL*/

  /*FORMIO TEXTAREA*/

  .formio-component-textarea {
    textarea {
      /*content:styles formio TEXTAREA OK */

      width: 100% !important;
      background: $primary-color-light !important;
      border: 1px solid $input-corporate-border-color !important;
      border-radius: $input-corporate-border-radius !important;
      box-shadow: $input-corporate-box-shadow !important;
      color: $input-corporate-text-color !important;
      font-family: $primary-corporate-font !important;
      transition: all 0.6s ease !important;
      /* padding: 0.5rem 0.5rem !important;*/
      padding: 8px 16px 4px 4px !important;
      font-family: $primary-corporate-font !important;
      font-size: 1rem !important;
      font-weight: normal !important;
      padding-left: 10px !important;
    }

    textarea:hover,
    input:enabled:hover,
    input:enabled:focus {
      /*content:styles formio input hover focus OK */
      border: 1px solid $input-corporate-border-color !important;
      box-shadow: $input-corporate-box-shadow-hover !important;
    }

    textarea:disabled {
      /*content:styles formio input disbled OK */
      background-color: $input-corporate-background-color-disabled !important;
      box-shadow: none !important;
      opacity: 1 !important;
      font-weight: normal !important;
      padding-left: 10px !important;
    }
  }

  /*END FORMIO TEXTAREA*/

  /*FORMIO SELECT DROPDOWN*/

  .formio-component-select,
  .formio-component-day {
    .dropdown,
    select {
      /*content:styles formio DROPDOWN, SELECT OK */
      display: block;
      background: $primary-color-light;
      border: 1px solid $select-corporate-border-color;
      color: $select-corporate-text-color;
      box-shadow: $select-corporate-box-shadow;
      border-radius: $select-corporate-border-radius;
      width: 100%;
      transition: all 0.6s ease !important;
      /*padding: 0.5rem 0.5rem !important;
        padding: 5px 20px !important;
        font-weight: normal !important;
        padding-left: 10px !important;
        */

      padding: 0px !important;
      padding-left: 10px !important;
      height: 45px !important;
    }

    .dropdown:hover,
    select:hover {
      border: 1px solid $select-corporate-border-color;
      box-shadow: $input-corporate-box-shadow-hover !important;
    }

    .dropdown:not(.p-disabled):hover {
      border-color: $primary-color-light !important;
    }

    .choices__list--dropdown {
      border: none;
      color: $select-corporate-text-color;
      box-shadow: $select-corporate-box-shadow;
      border-radius: $select-corporate-border-radius;
      width: 100%;
    }

    .choices__list--single {
      padding: 10px 16px 4px 4px !important;
    }

    .dropdown[disabled="disabled"] {
      /*content:styles formio DROPDOWN, SELECT DISABLED OK */
      padding: 5px 5px !important;
      background-color: $input-corporate-background-color-disabled !important;
      /* box-shadow: none !important;*/
      opacity: 1 !important;
      /*box-shadow: $select-corporate-box-shadow !important;*/
      box-shadow: none !important;
      font-weight: normal !important;
      /* padding-left: 10px !important;*/

      padding: 0px !important;
      padding-left: 10px !important;
      height: 45px !important;
    }
  }
  /*END FORMIO SELECT DRDOPDOWN*/

  /*FORMIO CHECK AND RADIO*/

  .form-check .form-check-input {
    /*content:styles formio CHECK RADIO OK */
    float: inherit;
    margin-left: inherit;
    cursor: pointer !important;
    font-weight: normal !important;
  }

  .form-check-input:checked {
    background-color: $checkbox-corporate-background-color !important;
    color: $checkbox-corporate-color !important;
    border-color: $checkbox-corporate-border-color !important;
    box-shadow: $checkbox-corporate-box-shadow;
  }

  .form-check-input:hover {
    box-shadow: $checkbox-corporate-box-shadow-hover !important;
  }

  .form-check-input:focus {
    box-shadow: $checkbox-corporate-box-shadow-hover !important;
  }

  .form-check-label,
  .form-check-input {
    cursor: pointer !important;
  }

  /*END FORMIO CHECK AND RADIO*/

  /*BUTTON*/
  .formio-component-button {
    .btn:not(.custom-button) {
      /*
       color: $primary-color-light !important;
      background: $primary-corporate-color !important;
    
        color:  $button-text-color !important;
        background:  $button-background-color;
          */
    }

    .btn {
      /*content:styles formio BUTTON  OK */
      padding: 5px 20px !important;
      font-size: 16px !important;
      border: none !important;
      cursor: pointer !important;
      text-align: center !important;
      width: auto !important;
      justify-content: center !important;
      align-items: center !important;
      transition: background-color 0.3s ease;
      /*margin: 10px auto 0 auto;*/
      margin: auto;
      border-radius: 8px !important;
      font-weight: normal !important;
    }

    .btn:hover,
    .btn:enabled:hover {
      /*
        color: $button-text-color!important;
        background:$button-background-color-hover;
        */

      padding: 5px 20px !important;
      transition: all 0.6s ease !important;
    }

    .btn:not(.custom-button):hover,
    .btn:not(.custom-button):enabled:hover {
      /*
        color: $primary-color-light !important;
        background: $secondary-corporate-color !important;
        */

      padding: 5px 20px !important;

      transition: all 0.6s ease !important;
    }
  }

  .icon-button {
    .btn {
      font-size: 0px !important;
      /*background: none !important;*/

      span.fa {
        font-size: 20px !important;
        margin-right: 10px !important;
      }

      .text-muted {
        color: white !important;
        font-size: 20px !important;
      }
    }
  }



  /*END BUTTON*/

  /*DATETIME*/

  .formio-component-datetime {
    /*content:styles formio DATETIME COMPONENT OK */

    input {
      background: $primary-color-light !important;
      border: 1px solid $input-corporate-border-color !important;
      color: $input-corporate-text-color !important;
      box-shadow: $input-corporate-box-shadow !important;
      border-radius: $input-corporate-border-radius !important;
      transition: all 0.6s ease !important;
      /*padding: 0.5rem 0.5rem !important;
        padding: 8px 16px 4px 4px !important;
        font-weight: normal !important;
        padding-left: 10px !important;
        */

      padding: 0px !important;
      padding-left: 10px !important;
      height: 45px !important;
    }

    input:hover {
      border: 1px solid $input-corporate-border-color !important;
      box-shadow: $input-corporate-box-shadow-hover !important;
    }

    input:not(.p-disabled):hover {
      border-color: $primary-color-light !important;
    }

    input:disabled {
      background-color: $input-corporate-background-color-disabled !important;
      box-shadow: none !important;
      opacity: 1 !important;
      font-weight: normal !important;

      padding: 0px !important;
      padding-left: 10px !important;
      height: 45px !important;
    }

    span.input-group-text {
      font-size: 1.5rem !important;
      margin-left: 6px;
      margin-top: 3px;
      color: $primary-color-light;
      background-color: $button-formio-calendar-bgcolor;
      cursor: pointer;
      transition: all 0.6s ease !important;
      font-weight: normal !important;
      padding: 9px !important;
    }

    span.input-group-text:hover {
      background-color: $button-formio-calendar-bgcolor-hover;
    }
  }
  /*END DATETIME*/

  /*FORMIO SIGNATURE*/

  .formio-component-signature {
    .signature-pad-body {
      /*content:styles formio SIGNATURE OK */
      transition: all 0.6s ease !important;
      border: 1px solid $input-corporate-border-color !important;
      border-radius: $input-corporate-border-radius !important;
      box-shadow: $input-corporate-box-shadow !important;
      padding-left: 1px !important;
      padding-right: 1px !important;
      font-weight: normal !important;
    }

    .signature-pad-body:hover,
    .signature-pad-body:enabled:hover,
    .signature-pad-body:enabled:focus {
      /*content:styles formio input hover focus OK */
      border: 1px solid $input-corporate-border-color !important;
      box-shadow: $input-corporate-box-shadow-hover !important;
    }
  }
  /*END FORMIO SIGNATURE*/

  /*ALIGN RIGHT INPUT CURRENCY*/

  .text-right input {
    text-align: right;
    padding-right: 3px;
  }

  /*END ALIGN RIGHT INPUT CURRENCY*/


  /*OVERA FILE UPLOAD OVERRIDE*/

  formio-overa-file-upload,
  formio-overa-file-upload-multiple {
    .o-fileupload-input {
      max-width: 100% !important;
      padding: 0px !important;
      padding-left: 10px !important;
      height: 48px !important;
    
    }

    .p-button.p-button-icon-only {
      height: 48px !important;
      padding: 15px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      margin-top: 0px !important;     
    }
    
    
  } 
  
  /*END OVERA FILE UPLOAD OVERRIDE*/




  .card-body.bg-light {
    border: none !important;
    background: none !important;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  div[ref^="nested-no-well-with-border"],
  div[ref^="nested-well-with-border"] {
    border: 1px solid grey;
    border-radius: 6px;

    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }

  /*CENTER FOOTER BUTTONS*/

  .footer-buttons {
    .card {
      justify-content: center !important;
      flex-direction: row !important;
      margin-bottom: 1rem !important;
    }

    div[ref^="nested-footer-buttons"] {
      gap: 1.5rem !important;
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;
    }

    .formio-component {
      width: auto !important;
    }
  }

  /*END CENTER FOOTER BUTTONS*/

  /* SOLAPAS*/

  .pagination {
    align-items: flex-end;
    margin-bottom: 50px !important;
  }

  li.page-item {
    /*margin-right: 5px !important;*/
  }

  div > nav > ul.pagination .page-link {
    color: $primary-corporate-color !important;
    transition: all 0.6s ease !important;
    border-radius: 6px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  div > nav > ul.pagination .page-link:hover {
    color: $primary-color-light !important;
    background: $primary-corporate-color !important;
    transition: all 0.6s ease !important;
    height: 40px !important;
    border-color: #dee2e6 !important;
  }

  div > nav > ul.pagination .page-item.active .page-link {
    color: $primary-color-light !important;
    background: $primary-corporate-color !important;
    transition: all 0.6s ease !important;
    height: 40px !important;
    border-radius: 6px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-color: #dee2e6 !important;
  }

  /* FIN SOLAPAS*/


  /*SOLAPAS card-header-tabs*/

  .nav-tabs .nav-link
  {
    border-color: var(--bs-nav-tabs-link-hover-border-color);
    transition: all 0.6s ease !important;
    color: $primary-corporate-color;
  }


  .nav-tabs .nav-link:hover
  {
    background-color: white;
    text-decoration:none;

  }

  .nav-tabs .nav-link.active
 {
  text-decoration:none !important;
  color: $primary-corporate-color;
 }

  /*FIN SOLAPAS card-header-tabs*/






  /*TABLAS HTML AJUSTAR TR*/
  .formio-component-htmlelement table td p {
    margin-top: 14px !important;
    margin-bottom: 5px !important;
  }
  
  .formio-component-htmlelement table td {
    border-bottom: 1px solid #e9ecef !important;
  }

  


  
 .formio-component-htmlelement table tr:last-child 
  {
    border: 0px;
  }
  
  
  /*FIN TABLAS HTML AJUSTAR TR*/







  /*OVERRIDE POSITION OVERA CHECKBOX*/

  .o-checkbox-checklabel::after {
    top: 1.5px !important;
    left: 9px !important;
  }
  /*END OVERRIDE POSITION OVERA CHECKBOX*/

  /*FIX MOBILE */

  @media only screen and (max-width: 1024px) {
    /* FIX CONTAINER MOBILE PADDINGS*/

    .card-body.bg-light,
    .container-fluid {
      padding: 1px !important;
    }

    /*Check labels next line if oferflow*/

    .form-check-label {
      padding-left: 0px !important;
      white-space: normal !important;
    }

    div[ref^="nested-no-well-with-border"],
    div[ref^="nested-well-with-border"] {
      margin-top: 15px !important;
    }

    .form-group {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }

    /*END FIX CONTAINER MOBILE PADDINGS*/

    /*FIX MOBILE SOLAPAS*/

    div > nav > ul.pagination {
      flex-flow: column !important;
      justify-content: flex-start !important;
    }

    li.page-item,
    div > nav > ul.pagination .page-link,
    div > nav > ul.pagination .page-link:hover,
    div > nav > ul.pagination .page-item.active .page-link {
      width: 100% !important;
    }

    /*END FIX MOBILE SOLAPAS*/
  }

  .top-buttons-wizard {
    position: absolute;
    top: 50px;
    right: 20px;
  }
  .hidden-well {
    visibility: hidden;
    height: 0;
    width: 0;
  }
} 

/*END FORMIO FORM*/

/*ICONO NEGRO BORRAR AJUSTARLO MAS ABAJO*/
.formio-component-datagrid .datagrid-table > tbody > tr > td:last-child {
  padding-top: 60px;
}
/*FIN ICONO NEGRO BORRAR AJUSTARLO MAS ABAJO*/




.formio-button-remove.now .fa-times-circle-o::before 
{
  /*content: "\f05c"; */
  content: "\f1f8";
}












  /*PANTALLA DATA RETENTION POLICY*/

.page-retention-policy label.form-check-label
{
  display: inline !important;

}

   /*FIN PANTALLA DATA RETENTION POLICY*/





/*FIN PERSONALIZACION PANTALLAS*/



.align-content-center {
  align-content: center;
}

.ml-3 .form-check
{
  margin-left: 1rem ;

}

.formio-form .formio-component .margin-t0
{
  margin-top: 0px !important;
}


/*MIN HEIGHT TEXT AREA CSS*/
.css-editor .o-text-area-input
    
{
    min-height: 700px;
}
